import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WhyChooseUs from './includes/WhyChooseUs';
import ImageDisclaimer from './includes/ImageDisclaimer';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import CalculatePayment from './includes/CalculatePayment';
import SwiperCore, { Pagination } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);
const Main = () => {
	const location = useLocation();
	useEffect(() => {
		if (location.pathname.endsWith('calculate')) {
			setTimeout(() => {
				const calculateDiv = document.getElementById('calculate-payment');
				calculateDiv &&
					calculateDiv.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'end',
					});
			});
		}
	}, []);
	return (
		<>
			{/* ======= Hero Section ======= */}
			<section id='hero' className='hero d-flex align-items-center'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6 d-flex flex-column justify-content-center'>
							<h1 data-aos='fade-up'>
								Looking For Short-Term Loans of Higher Amounts?
							</h1>
							<h2 data-aos='fade-up' data-aos-delay={400}>
								Get instant approved loans straight into your bank account at
								flat 8% Per Annum.
							</h2>
							<div data-aos='fade-up' data-aos-delay={600}>
								<div className='text-center text-lg-start'>
									<Link
										to='/apply-for-loan'
										className='btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center'>
										<span>Get Started</span>
										<i className='bi bi-arrow-right' />
									</Link>
								</div>
							</div>
						</div>
						{/* <div
							className='col-lg-6 hero-img'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								src={
									require('../../assets/front_assets/assets/img/hero-img.png')
										.default
								}
								className='img-fluid'
								alt=''
							/>
						</div> */}
					</div>
				</div>
			</section>
			{/* End Hero */}

			<main id='main'>
				<WhyChooseUs />

				<CalculatePayment />

				{/* ======= Get hassle-free loan section ======= */}
				<section id='about' className='why-choose-us'>
					<div className='container' data-aos='fade-up'>
						<header className='section-header'>
							<p>Get Hassle-free Loans in Just Few Clicks</p>
						</header>
						<div className='row gx-0'>
							<div
								className='col-lg-12 d-flex align-items-center'
								data-aos='zoom-out'
								data-aos-delay={200}>
								<img
									src={
										require('../../assets/front_assets/assets/img/hassel-free.png')
											.default
									}
									className='img-fluid'
									alt=''
								/>
							</div>
						</div>
					</div>
				</section>
				{/* End Get hassle-free loan section */}

				{/* =======Start WE ARE HERE FOR YOU Section ======= */}
				<section id='testimonials' className='testimonials'>
					<div className='container' data-aos='fade-up'>
						<header className='section-header'>
							<p>WE ARE HERE FOR YOU</p>
							<br />
							<h2>
								You are not alone in these difficult times. We are here to ease{' '}
								<br />
								your burden amidst the financial crisis with our quick and
								easy-approved loans.
							</h2>
						</header>
						<div
							className='swiper-container'
							data-aos='fade-up'
							data-aos-delay={200}>
							<Swiper
								pagination={{
									clickable: true,
								}}
								slidesPerView={3}
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 20,
									},
									480: {
										slidesPerView: 1,
										spaceBetween: 20,
									},
									640: {
										slidesPerView: 2,
										spaceBetween: 10,
									},
									992: {
										slidesPerView: 3,
										spaceBetween: 20,
									},
								}}
								className='mySwiper'>
								<SwiperSlide>
									<div className='testimonial-item'>
										<div className='profile'>
											<img
												src={
													require('../../assets/front_assets/assets/img/swiper-slider/round-the-clock.png')
														.default
												}
												className='testimonial-img'
												alt=''
											/>
											<h3>Round the Clock</h3>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className='testimonial-item'>
										<div className='profile mt-auto'>
											<img
												src={
													require('../../assets/front_assets/assets/img/swiper-slider/simplified-application.png')
														.default
												}
												className='testimonial-img'
												alt=''
											/>
											<h3>Simplified Application to Approval Process</h3>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className='testimonial-item'>
										<div className='profile mt-auto'>
											<img
												src={
													require('../../assets/front_assets/assets/img/swiper-slider/transperent-fees.png')
														.default
												}
												className='testimonial-img'
												alt=''
											/>
											<h3>Transparent Fees Structures</h3>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className='testimonial-item'>
										<div className='profile mt-auto'>
											<img
												src={
													require('../../assets/front_assets/assets/img/swiper-slider/data-privacy.png')
														.default
												}
												className='testimonial-img'
												alt=''
											/>
											<h3>Data Privacy</h3>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className='testimonial-item'>
										<div className='profile mt-auto'>
											<img
												src={
													require('../../assets/front_assets/assets/img/swiper-slider/team-to-help.png')
														.default
												}
												className='testimonial-img'
												alt=''
											/>
											<h3>A Team that Loves to Help!</h3>
										</div>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</section>
				{/* End WE ARE HERE FOR YOU Section */}

				<ImageDisclaimer />

				{/* End Recent Blog Posts Section */}
			</main>
			{/* End #main */}
		</>
	);
};

export default Main;
