import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { createLoanAndUserAPI, checkForEmail, showSpinner, hideSpinner } from '../../services/api';
import MainContexts from '../../contexts/MainContexts';
import { getToken } from 'services/dataService';
import jwtDecode from 'jwt-decode';

const ApplyForLoan = () => {
    const calculateData = useContext(MainContexts);
    const [months, setMonths] = useState(0);
    const [initialAmount, setInitialAmount] = useState('');
    const [installments, setInstallments] = useState('');
    const [creditScore, setCreditScore] = useState('');

    const personalDetFormFields = {
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNo1: '',
        phoneNo2: '',
        dob: '',
        ssn: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pinCode: '',
        residingYear: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
    };

    const personalDetValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        middleName: Yup.string().required('Middle name is required'),
        lastName: Yup.string().required('last name is required'),
        phoneNo1: Yup.string().min(10).required('Phone number is required'),
        phoneNo2: Yup.string().min(10).required('Home phone number is required'),
        dob: Yup.string().required('DOB is required'),
        ssn: Yup.string().min(9, 'SSN must be at least 9 digits').required('SNN is required'),
        address1: Yup.string().required('Address 1 is required'),
        address2: Yup.string().required('Address 2 is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        pinCode: Yup.string()
            .max(5, 'Zincode should not be more than 5 digits')
            .min(5, 'ZinCode must be at least 5 digits')
            .required('Zipcode is required'),
        residingYear: Yup.string().required('Residing year is required'),
        email: !getToken() ? Yup.string().email('Email is invalid').required('Email is required') : '',
        confirmEmail: !getToken()
            ? Yup.string()
                  .oneOf([Yup.ref('email'), null], 'Email must match')
                  .required('Confirm email is required')
            : '',
        password: !getToken() ? Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required') : '',
        confirmPassword: !getToken()
            ? Yup.string()
                  .oneOf([Yup.ref('password'), null], 'Passwords must match')
                  .required('Confirm Password is required')
            : '',
    });

    const bankDetFormFields = {
        bankName: '',
        accountType: '',
        accountNo: '',
        routingNo: '',
        bankAddress: '',
    };

    const bankDetValidationSchema = Yup.object().shape({
        bankName: Yup.string().required('Bank name is required'),
        accountType: Yup.string().required('Account type is required'),
        accountNo: Yup.string().required('Account no is required'),
        routingNo: Yup.string().required('Routing no is required'),
        bankAddress: Yup.string().required('Bank address is required'),
    });

    const loanDetFormFields = {
        initialAmount: initialAmount,
        months: months,
        repaymentMonth: installments,
        creditScore: creditScore,
        loanType: '',
    };

    const loanDetValidationSchema = Yup.object().shape({
        initialAmount: Yup.number()
            .min(1000, 'Initial amount must be at least 1000')
            .max(15000, 'Initial amount must be less than 15000')
            .required('Initial amount is required'),
        months: Yup.string().required('Tenure is required'),
        repaymentMonth: Yup.string().required('Installment amount is required'),
        creditScore: Yup.string().required('Credit score is required'),
        loanType: Yup.string().required('Loan type is required'),
    });

    const [personalDetails, setPersonalDetails] = useState(null);
    const [bankDetails, setBankDetails] = useState(null);
    const [loanDetails, setLoanDetails] = useState(null);

    const [displayPersonal, setDisplayPersonal] = useState(true);
    const [displayBank, setDisplayBank] = useState(false);
    const [displayLoan, setDisplayLoan] = useState(false);
    const [displayLoanApproval, setDisplayLoanApproval] = useState(false);
    const [wizard, setWizard] = useState(true);

    const [pastLoanValue, setPastLoanValue] = useState(null);
    const [pendingLoanValue, setPendingLoanValue] = useState(null);
    const [pendingBillsValue, setPendingBillsValue] = useState(null);

    const [displayAccountNo, setDisplayAccountNo] = useState(null);
    const [bankName, setBankName] = useState('');

    const [residencyTime, Residency] = useState([
        { value: 'less than 1 year', label: 'Less Than 1 Year' },
        { value: '2 year', label: '2 Year' },
        { value: '3 year', label: '3 Year' },
        { value: '4 year', label: '4 Year' },
        { value: '5 year', label: '5 Year' },
        { value: '6 year', label: '6 Year' },
        { value: '7 year', label: '7 Year' },
        { value: 'more than 7 year', label: 'More than 7 Year' },
    ]);

    const [typeOfAccount, BankAccount] = useState([
        { value: 'saving', label: 'Saving Account' },
        { value: 'checking', label: 'Checking Account' },
        { value: 'virtual', label: 'Virtual Account' },
    ]);

    const [loanMonths, Months] = useState([
        { value: '12', label: '12 Months [1 Year]' },
        { value: '24', label: '24 Months [2 Year]' },
        { value: '36', label: '36 Months [3 Year]' },
        { value: '48', label: '48 Months [4 Year]' },
        { value: '60', label: '60 Months [5 Year]' },
    ]);

    const [loanType, LoanType] = useState([
        { value: 'personal', label: 'Personal Loan' },
        { value: 'payday', label: 'Payday Loan' },
        { value: 'medical', label: 'Medical Loan' },
        { value: 'business', label: 'Business Loan' },
    ]);

    const [creditArray, setCreditArray] = useState([
        { value: 'Very Poor', label: 'Very Poor' },
        { value: 'Poor', label: 'Poor' },
        { value: 'Fair', label: 'Fair' },
        { value: 'Good', label: 'Good' },
        { value: 'Excellent', label: 'Excellent' },
    ]);

    const addPersonalDetails = (data, resetForm) => {
        if (!getToken()) {
            checkForEmail({ email: data.email }, true).then((result) => {
                if (result && result.success) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    setPersonalDetails(data);
                    setDisplayBank(true);
                    setDisplayPersonal(false);
                    setDisplayLoan(false);
                    setDisplayLoanApproval(false);
                } else {
                    toast.error(result && result.message);
                    return;
                }
            });
        } else {
            const jwtData = jwtDecode(getToken() && getToken());
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setPersonalDetails({ ...data, userId: jwtData.user.id });
            setDisplayBank(true);
            setDisplayPersonal(false);
            setDisplayLoan(false);
            setDisplayLoanApproval(false);
        }
    };

    const addBankDetails = (data, resetForm) => {
        setBankName(data?.bankName);
        setDisplayAccountNo(data.accountNo);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setBankDetails(data);
        setDisplayBank(false);
        setDisplayPersonal(false);
        setDisplayLoan(true);
        setDisplayLoanApproval(false);
    };

    const addLoanDetails = (data, resetForm) => {
        let obj = {};
        setLoanDetails(data);
        setDisplayBank(false);
        setDisplayPersonal(false);
        setDisplayLoan(false);
        obj = {
            personalDetails,
            bankDetails,
            loanDetails: {
                ...data,
                isPastLoan: pastLoanValue === 'Yes' ? true : false,
                isPendingLoan: pendingLoanValue === 'Yes' ? true : false,
                isPendingBills: pendingBillsValue === 'Yes' ? true : false,
            },
        };

        showSpinner();
        setTimeout(() => {
            createLoanAndUserAPI(obj).then((result) => {
                if (result && result.success) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    setDisplayLoanApproval(true);
                    setWizard(false);
                    setPersonalDetails(null);
                    setLoanDetails(null);
                    setBankDetails(null);
                    resetForm();
                } else {
                    resetForm();
                    setPersonalDetails(null);
                    setLoanDetails(null);
                    setBankDetails(null);
                    setDisplayPersonal(true);
                    setDisplayBank(false);
                    setDisplayLoan(false);
                    setDisplayLoanApproval(false);
                    toast.error(result.message);
                }
                hideSpinner();
            });
        }, 5000);
    };

    const onHandleChange = (e) => {
        if (e.target.name === 'isPastLoan') {
            setPastLoanValue(e.target.value);
        }
        if (e.target.name === 'isPendingLoan') {
            setPendingLoanValue(e.target.value);
        }
        if (e.target.name === 'isPendingBills') {
            setPendingBillsValue(e.target.value);
        }
    };

    useEffect(() => {
        const data = calculateData && calculateData[0] && calculateData[0].contextData && calculateData[0].contextData[0];
        const initValue = data && data.value;
        const initMonths = data && data.loanValue * 12;
        const creditScore = data && data.finalCreditScore;
        setInitialAmount(initValue);
        setMonths(initMonths);
        setCreditScore(creditScore);
        calculation(initValue, initMonths);
        return () => {
            setInitialAmount(0);
            setMonths(0);
            setInstallments('');
            setPersonalDetails(null);
            setLoanDetails(null);
            setBankDetails(null);
            setDisplayPersonal(true);
            setDisplayBank(false);
            setDisplayLoan(false);
            setDisplayLoanApproval(false);
            setWizard(true);
        };
    }, []);

    const handleInitialAmount = (e) => {
        setInitialAmount(e.target.value);
        if (initialAmount && months) {
            calculation(initialAmount, months);
        }
    };

    const handleMonths = async (e) => {
        setMonths(e.target.value);
        if (initialAmount) {
            calculation(parseInt(initialAmount), parseInt(e.target.value));
        }
    };

    const calculation = (value, loanValue) => {
        const finalValue = (value / loanValue + (value * (8 * 0.01)) / loanValue).toFixed(2);
        setInstallments(finalValue);
    };

    return (
        <>
            <main id="main">
                {/* ======= Apply for loan Section ======= */}
                <section id="contact" className="contact mt-5">
                    <div className="container" data-aos="fade-up">
                        {!displayLoanApproval && (
                            <header className="section-header mb-5">
                                <p>Apply for loan</p>
                            </header>
                        )}
                        <div className="row gy-4">
                            {displayPersonal && (
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        addPersonalDetails(values, resetForm);
                                    }}
                                    initialValues={personalDetFormFields}
                                    enableReinitialize
                                    validationSchema={personalDetValidationSchema}
                                    render={({ handleSubmit, errors, touched }) => (
                                        <div className="col-lg-6 mr-5">
                                            <form className="php-email-form" onSubmit={handleSubmit}>
                                                <h3 className="text-green font-weight-bold pb-3">Enter Personal Details</h3>
                                                <div className="row gy-4">
                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.firstName && touched.firstName ? ' error-box is-invalid' : '')
                                                            }
                                                            name="firstName"
                                                            placeholder="First Name*"
                                                        />
                                                        <ErrorMessage
                                                            name="firstName"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.middleName && touched.middleName ? ' error-box is-invalid' : '')
                                                            }
                                                            name="middleName"
                                                            placeholder="Middle Name*"
                                                        />
                                                        <ErrorMessage
                                                            name="middleName"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.lastName && touched.lastName ? ' error-box is-invalid' : '')
                                                            }
                                                            name="lastName"
                                                            placeholder="Last Name*"
                                                        />
                                                        <ErrorMessage
                                                            name="lastName"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <Field
                                                            type="number"
                                                            name="phoneNo1"
                                                            className={
                                                                'form-control' +
                                                                (errors.phoneNo1 && touched.phoneNo1 ? ' error-box is-invalid' : '')
                                                            }
                                                            placeholder="Phone Number*"
                                                        />
                                                        <ErrorMessage
                                                            name="phoneNo1"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 ">
                                                        <Field
                                                            type="number"
                                                            className={
                                                                'form-control' +
                                                                (errors.phoneNo2 && touched.phoneNo2 ? ' error-box is-invalid' : '')
                                                            }
                                                            name="phoneNo2"
                                                            placeholder="Home Phone Number*"
                                                        />
                                                        <ErrorMessage
                                                            name="phoneNo2"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 ">
                                                        <Field
                                                            type="date"
                                                            className={
                                                                'form-control' + (errors.dob && touched.dob ? ' error-box is-invalid' : '')
                                                            }
                                                            name="dob"
                                                            placeholder="DOB*"
                                                        />
                                                        <ErrorMessage name="dob" component="div" className="is-invalid invalid-feedback" />
                                                    </div>

                                                    <div className="col-md-6 ">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' + (errors.ssn && touched.ssn ? ' error-box is-invalid' : '')
                                                            }
                                                            name="ssn"
                                                            placeholder="SSN*"
                                                        />
                                                        <ErrorMessage name="ssn" component="div" className="is-invalid invalid-feedback" />
                                                    </div>
                                                </div>

                                                <h3 className="text-green font-weight-bold pt-5 pb-3">Home Address</h3>
                                                <div className="row gy-4">
                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.address1 && touched.address1 ? ' error-box is-invalid' : '')
                                                            }
                                                            name="address1"
                                                            placeholder="Address Line 1*"
                                                        />
                                                        <ErrorMessage
                                                            name="address1"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.address2 && touched.address2 ? ' error-box is-invalid' : '')
                                                            }
                                                            name="address2"
                                                            placeholder="Address Line 2*"
                                                        />
                                                        <ErrorMessage
                                                            name="address2"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <Field
                                                            type="text"
                                                            name="city"
                                                            className={
                                                                'form-control' +
                                                                (errors.city && touched.city ? ' error-box is-invalid' : '')
                                                            }
                                                            placeholder="City*"
                                                        />
                                                        <ErrorMessage name="city" component="div" className="is-invalid invalid-feedback" />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.state && touched.state ? ' error-box is-invalid' : '')
                                                            }
                                                            name="state"
                                                            placeholder="State*"
                                                        />
                                                        <ErrorMessage
                                                            name="state"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.pinCode && touched.pinCode ? ' error-box is-invalid' : '')
                                                            }
                                                            name="pinCode"
                                                            placeholder="Zip Code*"
                                                        />
                                                        <ErrorMessage
                                                            name="pinCode"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 ">
                                                        <Field
                                                            as="select"
                                                            name="residingYear"
                                                            className={
                                                                'form-control form-select front-select' +
                                                                (errors.residingYear && touched.residingYear ? ' error-box is-invalid' : '')
                                                            }
                                                        >
                                                            <option value="">Residing Since</option>
                                                            {residencyTime.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="residingYear"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                                {!getToken() && (
                                                    <>
                                                        <h3 className="text-green font-weight-bold pt-5 pb-3">Create Account</h3>
                                                        <div className="row gy-4">
                                                            <div className="col-md-6">
                                                                <Field
                                                                    type="text"
                                                                    className={
                                                                        'form-control' +
                                                                        (errors.email && touched.email ? ' error-box is-invalid' : '')
                                                                    }
                                                                    name="email"
                                                                    placeholder="Email*"
                                                                />
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <Field
                                                                    type="text"
                                                                    className={
                                                                        'form-control' +
                                                                        (errors.confirmEmail && touched.confirmEmail
                                                                            ? ' error-box is-invalid'
                                                                            : '')
                                                                    }
                                                                    name="confirmEmail"
                                                                    placeholder="Confirm Email*"
                                                                />
                                                                <ErrorMessage
                                                                    name="confirmEmail"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <Field
                                                                    type="password"
                                                                    name="password"
                                                                    className={
                                                                        'form-control' +
                                                                        (errors.password && touched.password ? ' error-box is-invalid' : '')
                                                                    }
                                                                    placeholder="Password*"
                                                                />
                                                                <ErrorMessage
                                                                    name="password"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <Field
                                                                    type="password"
                                                                    className={
                                                                        'form-control' +
                                                                        (errors.confirmPassword && touched.confirmPassword
                                                                            ? ' error-box is-invalid'
                                                                            : '')
                                                                    }
                                                                    name="confirmPassword"
                                                                    placeholder="Confirm Password*"
                                                                />
                                                                <ErrorMessage
                                                                    name="confirmPassword"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="col-md-12 mt-3 text-center">
                                                    <button type="submit">Save & Continue</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                />
                            )}

                            {displayBank && (
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        addBankDetails(values, resetForm);
                                    }}
                                    initialValues={bankDetFormFields}
                                    enableReinitialize
                                    validationSchema={bankDetValidationSchema}
                                    render={({ handleSubmit, errors, touched }) => (
                                        <div className="col-lg-6 mr-5">
                                            <form className="php-email-form" onSubmit={handleSubmit}>
                                                <h3 className="text-green font-weight-bold pb-3">Bank Details</h3>
                                                <div className="row gy-4">
                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.bankName && touched.bankName ? ' error-box is-invalid' : '')
                                                            }
                                                            name="bankName"
                                                            placeholder="Bank Name*"
                                                        />
                                                        <ErrorMessage
                                                            name="bankName"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.accountNo && touched.accountNo ? ' error-box is-invalid' : '')
                                                            }
                                                            name="accountNo"
                                                            placeholder="Account Number*"
                                                        />
                                                        <ErrorMessage
                                                            name="accountNo"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.routingNo && touched.routingNo ? ' error-box is-invalid' : '')
                                                            }
                                                            name="routingNo"
                                                            placeholder="Routing No*"
                                                        />
                                                        <ErrorMessage
                                                            name="routingNo"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.bankAddress && touched.bankAddress ? ' error-box is-invalid' : '')
                                                            }
                                                            name="bankAddress"
                                                            placeholder="Bank Address*"
                                                        />
                                                        <ErrorMessage
                                                            name="bankAddress"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            as="select"
                                                            name="accountType"
                                                            className={
                                                                'form-control form-select front-select' +
                                                                (errors.accountType && touched.accountType ? ' error-box is-invalid' : '')
                                                            }
                                                        >
                                                            <option value="">Type of Account</option>
                                                            {typeOfAccount.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="accountType"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12 text-center">
                                                        <button type="submit">Save & Continue</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                />
                            )}

                            {displayLoan && (
                                <Formik
                                    onSubmit={(values, { resetForm }) => {
                                        setLoanDetails(values);
                                        addLoanDetails(values, resetForm);
                                    }}
                                    initialValues={loanDetFormFields}
                                    enableReinitialize
                                    validationSchema={loanDetValidationSchema}
                                    render={({ handleSubmit, errors, touched }) => (
                                        <div className="col-lg-6 mr-5">
                                            <form className="php-email-form" onSubmit={handleSubmit}>
                                                <h3 className="text-green font-weight-bold pb-3">Loan Details</h3>
                                                <div className="row gy-4">
                                                    <div className="col-md-12">
                                                        <Field
                                                            type="number"
                                                            onChange={(e) => handleInitialAmount(e)}
                                                            className={
                                                                'form-control' +
                                                                (errors.initialAmount && touched.initialAmount
                                                                    ? ' error-box is-invalid'
                                                                    : '')
                                                            }
                                                            name="initialAmount"
                                                            placeholder="Initial Amount*"
                                                        />
                                                        <ErrorMessage
                                                            name="initialAmount"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            as="select"
                                                            name="months"
                                                            onChange={(e) => handleMonths(e)}
                                                            className={
                                                                'form-control form-select front-select' +
                                                                (errors.months && touched.months ? ' error-box is-invalid' : '')
                                                            }
                                                        >
                                                            <option value="">Tenure</option>
                                                            {loanMonths.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="months"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            type="number"
                                                            readOnly
                                                            className={
                                                                'form-control' +
                                                                (errors.repaymentMonth && touched.repaymentMonth
                                                                    ? ' error-box is-invalid'
                                                                    : '')
                                                            }
                                                            name="repaymentMonth"
                                                            placeholder="Installment Amount*"
                                                        />
                                                        <ErrorMessage
                                                            name="repaymentMonth"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            as="select"
                                                            name="creditScore"
                                                            className={
                                                                'form-control form-select front-select' +
                                                                (errors.creditScore && touched.creditScore ? ' error-box is-invalid' : '')
                                                            }
                                                        >
                                                            <option value="">Credit Score</option>
                                                            {creditArray.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="creditScore"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <Field
                                                            as="select"
                                                            name="loanType"
                                                            className={
                                                                'form-control form-select front-select' +
                                                                (errors.loanType && touched.loanType ? ' error-box is-invalid' : '')
                                                            }
                                                        >
                                                            <option value="">Loan Type</option>
                                                            {loanType.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="loanType"
                                                            component="div"
                                                            className="is-invalid invalid-feedback"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label className="form-control-label" htmlFor="input-isPastLoan">
                                                            Have you taken any loan in past ?
                                                        </label>
                                                        <div onChange={onHandleChange}>
                                                            <input type="radio" value="Yes" name="isPastLoan" /> Yes &nbsp;
                                                            <input type="radio" defaultChecked={true} value="No" name="isPastLoan" /> No
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label className="form-control-label" htmlFor="input-isPendingLoan">
                                                            Do you have any pending loan ?
                                                        </label>
                                                        <div onChange={onHandleChange}>
                                                            <input type="radio" value="Yes" name="isPendingLoan" /> Yes &nbsp;
                                                            <input defaultChecked={true} type="radio" value="No" name="isPendingLoan" /> No
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label className="form-control-label" htmlFor="input-isPendingLoan">
                                                            Do you have any credit cards, Medical bills pending ?
                                                        </label>
                                                        <div onChange={onHandleChange}>
                                                            <input type="radio" value="Yes" name="isPendingBills" /> Yes &nbsp;
                                                            <input defaultChecked={true} type="radio" value="No" name="isPendingBills" /> No
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 text-center">
                                                        <button type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                />
                            )}

                            {displayLoanApproval && (
                                <div id="approvedLoan" className="form-group row">
                                    <div className="col-xl-12 text-center">
                                        <img
                                            src={require('../../assets/front_assets/assets/img/approved2.svg').default}
                                            alt=""
                                            width="100px"
                                            height="100px"
                                        />
                                        <h3 className="mt-3">Loan Application Approved</h3>
                                        <p>Nice, your loan has been sanctioned according to your credit score.</p>
                                        <p>
                                            Login to the Easy Loan America Dashboard to transfer funds to your
                                            <strong>
                                                <span> {bankName}</span>
                                            </strong>{' '}
                                            and the account that ends with
                                            <strong>
                                                <span> {displayAccountNo?.toString().replace(/.(?=.{4})/g, 'X')}</span>
                                            </strong>
                                        </p>
                                        <a
                                            href="#!"
                                            onClick={() => {
                                                window.location.replace('https://eazyloanamerica.com/dashboard');
                                            }}
                                            className="btn btn-green-gradient"
                                        >
                                            <i className="bi bi-box-arrow-in-right login-icon" />
                                            Login here
                                        </a>
                                    </div>
                                </div>
                            )}

                            {wizard && (
                                <div className="col-lg-4">
                                    <div className="wizard-box">
                                        <div className="row gy-4">
                                            <div className="col-md-12">
                                                <div className={`${displayPersonal ? 'active-box' : 'inactive-box'}`}>
                                                    <div className="row">
                                                        <div className="col-md-4 col-4">
                                                            <div className="inner-box">
                                                                <p style={{ padding: '3px 9px 5px 21px' }} className="font-weight-bold">
                                                                    1
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-8 my-auto">
                                                            <h3>Personal Details</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`${displayBank ? 'active-box' : 'inactive-box'} mt-3`}>
                                                    <div className="row">
                                                        <div className="col-md-4 col-4">
                                                            <div className="inner-box">
                                                                <p style={{ padding: '3px 9px 5px 21px' }} className="font-weight-bold">
                                                                    2
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-8 my-auto">
                                                            <h3>Bank Details</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`${displayLoan ? 'active-box' : 'inactive-box'} mt-3`}>
                                                    <div className="row">
                                                        <div className="col-md-4 col-4">
                                                            <div className="inner-box">
                                                                <p style={{ padding: '3px 9px 5px 21px' }} className="font-weight-bold">
                                                                    3
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-8 my-auto">
                                                            <h3>Loan Details</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`${displayLoanApproval ? 'active-box' : 'inactive-box'} mt-3`}>
                                                    <div className="row">
                                                        <div className="col-md-4 col-4">
                                                            <div className="inner-box">
                                                                <p style={{ padding: '3px 9px 5px 21px' }} className="font-weight-bold">
                                                                    4
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-8 my-auto">
                                                            <h3>Loan Approval</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* End Apply for loan Section */}
            </main>
        </>
    );
};

export default ApplyForLoan;
