import React, { useEffect, useState } from 'react';
import { getAboutUsByIdAPI } from 'services/api';

const AboutUs = () => {
    const aboutUsId = 'a6aeee73-deaa-11ec-a625-f430b9920047';
    const [aboutUs, setAboutUs] = useState({});

    useEffect(() => {
        getAboutUsByIdAPI(aboutUsId).then((data) => {
            setAboutUs(data);
        });
    }, []);

    return (
        <>
            {/* ======= About us Section ======= */}
            <section id="about" className="about-us">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div
                            className="col-lg-5 col-12 col-md-12 flex-column justify-content-center"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="font-weight-bold text-green">About Us</h1>
                                        </div>
                                    </div> */}
                                    <div className="row about-margin-4">
                                        <div className="col-md-12">
                                            <h3 className="text-green">For Assistance :</h3>
                                        </div>
                                    </div>
                                    <div className="row about-margin-5">
                                        <div className="col-md-12 mt-2">
                                            <h4 className="ml-3">{aboutUs?.mobile1Text}</h4>
                                        </div>
                                        <div className="col-md-12 display-flex" style={{ display: 'flex' }}>
                                            <img
                                                className="float-left"
                                                style={{ marginRight: '8px' }}
                                                src={require('../../assets/front_assets/assets/img/about-us/phone-icon.png').default}
                                                width="25px"
                                                height="25px"
                                                alt=""
                                            />
                                            <h4 className="ml-4">{aboutUs?.mobile1}</h4>
                                        </div>

                                        <div className="col-md-12 mt-2">
                                            <h4 className="ml-3">{aboutUs?.mobile2Text}</h4>
                                        </div>
                                        <div className="col-md-12 display-flex" style={{ display: 'flex' }}>
                                            <img
                                                className="float-left"
                                                style={{ marginRight: '8px' }}
                                                src={require('../../assets/front_assets/assets/img/about-us/phone-icon.png').default}
                                                width="25px"
                                                height="25px"
                                                alt=""
                                            />
                                            <h4 className="ml-4">{aboutUs?.mobile2}</h4>
                                        </div>

                                        <div className="col-md-12 mt-2">
                                            <h4 className="ml-3">{aboutUs?.mobile3Text}</h4>
                                        </div>
                                        <div className="col-md-12 display-flex" style={{ display: 'flex' }}>
                                            <img
                                                className="float-left"
                                                style={{ marginRight: '8px' }}
                                                src={require('../../assets/front_assets/assets/img/about-us/phone-icon.png').default}
                                                width="25px"
                                                height="25px"
                                                alt=""
                                            />
                                            <h4 className="ml-4">{aboutUs?.mobile3}</h4>
                                        </div>

                                        <div className="col-md-12 mt-2 display-flex" style={{ display: 'flex' }}>
                                            <img
                                                className="float-left"
                                                style={{ marginRight: '8px' }}
                                                src={require('../../assets/front_assets/assets/img/about-us/email-icon.png').default}
                                                width="25px"
                                                height="25px"
                                                alt=""
                                            />
                                            <h4 className="ml-4">{aboutUs?.email1}</h4>
                                        </div>

                                        <div className="col-md-12 mt-1 mb-3 display-flex" style={{ display: 'flex' }}>
                                            <img
                                                className="float-left"
                                                style={{ marginRight: '8px' }}
                                                src={require('../../assets/front_assets/assets/img/about-us/email-icon.png').default}
                                                width="25px"
                                                height="25px"
                                                alt=""
                                            />
                                            <h4 className="ml-4">{aboutUs?.email2}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 d-flex align-items-center" data-aos="zoom-out" data-aos-delay={200}>
                            <img
                                src={require('../../assets/front_assets/assets/img/about-us/about-us.png').default}
                                style={{ maxWidth: '80%' }}
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* End About us Section */}
        </>
    );
};

export default AboutUs;
