import React from 'react';

const ImageDisclaimer = () => {
	return (
		<>
			{/* ======= About Section ======= */}
			<section id='about' className='about'>
				<div className='container' data-aos='fade-up'>
					<div className='row gx-0'>
						<div
							className='col-lg-9 col-md-12 col-12 d-flex align-items-center mx-auto'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								src={
									require('../../../assets/front_assets/assets/img/image-disclaimer/image-disclaimer.png')
										.default
								}
								className='img-fluid'
								alt=''
							/>
						</div>

						<div
							className='col-lg-5 col-md-8 col-11 disclaimer-box d-flex flex-column justify-content-center'
							data-aos='fade-up'
							data-aos-delay={200}>
							<div className='content'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='row'>
											<div className='col-md-2'>
												<img
													src={
														require('../../../assets/front_assets/assets/img/image-disclaimer/24hr.png')
															.default
													}
													width='50px'
													height='50px'
													className='img-fluid'
													alt=''
												/>
											</div>
											<div className='col-md-2 my-auto'>
												<h2 className='my-auto'>1000</h2>
											</div>
											<div className='col-md-7 my-auto'>
												<p>Loans Approved in 24 hr</p>
											</div>
										</div>
									</div>
									<div className='col-md-12 mt-3'>
										<div className='row'>
											<div className='col-md-2'>
												<img
													src={
														require('../../../assets/front_assets/assets/img/image-disclaimer/24hr.png')
															.default
													}
													width='50px'
													height='50px'
													className='img-fluid'
													alt=''
												/>
											</div>
											<div className='col-md-2 my-auto'>
												<h2 className='my-auto'>1000</h2>
											</div>
											<div className='col-md-7 my-auto'>
												<p>Applied Loans in 24 hr</p>
											</div>
										</div>
									</div>
									<div className='col-md-12 mt-3'>
										<div className='row'>
											<div className='col-md-2'>
												<img
													src={
														require('../../../assets/front_assets/assets/img/image-disclaimer/total-customer.png')
															.default
													}
													width='50px'
													height='50px'
													className='img-fluid'
													alt=''
												/>
											</div>
											<div className='col-md-2 my-auto'>
												<h2 className='my-auto'>1000</h2>
											</div>
											<div className='col-md-7 my-auto'>
												<p>Total Customer</p>
											</div>
										</div>
									</div>
									<div className='col-md-12 mt-3'>
										<div className='row'>
											<div className='col-md-2'>
												<img
													src={
														require('../../../assets/front_assets/assets/img/image-disclaimer/success-ratio.png')
															.default
													}
													width='50px'
													height='50px'
													className='img-fluid'
													alt=''
												/>
											</div>
											<div className='col-md-2 my-auto'>
												<h2 className='my-auto'>100%</h2>
											</div>
											<div className='col-md-7 my-auto'>
												<p>Success Ratio</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End About Section */}
		</>
	);
};

export default ImageDisclaimer;
