import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAboutUsByIdAPI } from 'services/api';

const Footer = () => {
	const aboutUsId = 'a6aeee73-deaa-11ec-a625-f430b9920047';
	const [aboutUs, setAboutUs] = useState({});

	useEffect(() => {
			getAboutUsByIdAPI(aboutUsId).then((data) => {
					setAboutUs(data);
			});
	}, []);
	return (
		<>
			{/* ======= Footer ======= */}
			<footer id='footer' className='footer'>
				<div className='footer-top'>
					<div className='container'>
						<div className='row gy-4'>
							<div className='col-lg-5 col-md-12 footer-info'>
								<Link to='/' className='logo d-flex align-items-center'>
									<img
										src={
											require('../../../assets/front_assets/assets/img/logo.png')
												.default
										}
										alt=''
									/>
								</Link>
								<p>
									Cras fermentum odio eu feugiat lide par naso tierra.
									<br /> Justo eget nada terra videa magna derita <br />
									valies darta donna mare fermentum iaculis eu non diam
									phasellus.
								</p>
							</div>
							<div className='col-lg-2 col-6 footer-links'>
								<h4>Useful Links</h4>
								<ul>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/'>Home</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/about-us'>About us</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/contact-us'>Contact us</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/privacy-policy'>Privacy Policy</Link>
									</li>
								</ul>
							</div>
							<div className='col-lg-2 col-6 footer-links'>
								<h4>Our Services</h4>
								<ul>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/housing-finance'>Housing Finance</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/emergency-loans'>Emergency Loans</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/personal-loans'>Personal Loans</Link>
									</li>
									<li>
										<i className='bi bi-chevron-right' />{' '}
										<Link to='/apply-for-loan'>Apply for loan</Link>
									</li>
								</ul>
							</div>
							<div className='col-lg-3 col-md-12 footer-contact text-center text-md-start'>
								<h4>Contact Us</h4>
								<p className='mt-0'>
								<strong>Address:</strong> {aboutUs?.address}
									<br />
									<strong>Phone:</strong> {aboutUs?.mobile1}
									<br />
									<strong>Email:</strong>{aboutUs?.email1}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='copyright'>
						<h4>
							<strong>Disclamier</strong>
						</h4>
						<br />
						<p>
							All loans are subject to credit approval and meeting the
							parameters set forth by Banco Popular de Puerto Rico. The APR
							ranges from 8.99% to 21.49%. Repayment terms go from 24 months to
							60 months. There are no origination and annual fees. There is no
							down payment required and there is no prepayment penalty. Final
							approval and credit decision timing may vary if additional
							documents are requested as part of the verification process.
							Verification process may vary by customer. Funds are generally
							deposited via ACH for delivery next business day if verification
							is completed and final approval occurs by 3:00 pm CT
							Monday-Friday. Business days are from Monday through Friday,
							excluding federal holidays. 3Loans start from $2,000 up to
							$35,000. The loan amount will be subject to credit approval.
						</p>
					</div>
					<div className='copyright'>
						© Copyright{' '}
						<strong>
							<span>Easy Loan America</span>
						</strong>
						. All Rights Reserved
					</div>
				</div>
			</footer>
			{/* End Footer */}
		</>
	);
};

export default Footer;
