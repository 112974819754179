import React from 'react';

const PrivacyPolicy = () => {
	return (
		<>
			{/* ======= F.A.Q Section ======= */}
			<section id='faq' className='faq mt-5'>
				<div className='container' data-aos='fade-up'>
					<header className='section-header'>
						<p>Privacy Policy</p>
					</header>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='accordion accordion-flush' id='faqlist1'>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-1'>
											Security and privacy policy
										</button>
									</h2>
									<div
										id='faq-content-1'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											Easy Loan America recognises its affirmative and ongoing
											duty to respect its customers' privacy and to safeguard
											the protection and confidentiality of their nonpublic
											personal details. Easy Loan America is committed to
											protecting your personal information by implementing (1)
											robust information management processes and (2) a strong
											enforcement culture.
											<br />
											<br />
											To verify your identity, prevent unauthorised sharing of
											your information, and keep your information secure, Easy
											Loan America employs a variety of technologically advanced
											processes. Easy Loan America is now training its employees
											on how to comply with current privacy laws and
											regulations.
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-2'>
											Guidance concepts
										</button>
									</h2>
									<div
										id='faq-content-2'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											Easy Loan America understands how important it is for its
											consumers to have access to non-public consumer personal
											information ("NPPI"). Easy Loan America not only complies
											with applicable legislation, but also employs industry
											best practises that go above and beyond what is strictly
											needed to keep us at the forefront of the industry in
											terms of customer service. The following are the guiding
											principles that Easy Loan America has followed:
											<br />
											<br />
											<div>
												<p>
													<strong>&bull; Protected Uses</strong>
												</p>
												Easy Loan America only collects and uses client
												information when it is required to carry out its
												business, which includes the provision of consumer
												loans.
												<br />
												<br />
												Except as permitted by law and as disclosed in its
												privacy notice, Easy Loan America does not share
												customer NPPI or consumer details
												<br />
												<br />
												Via the involvement of eligible industry-leading
												third-party service providers to assist with such
												services, Easy Loan America takes measures to fight data
												theft and discourage fraudulent transactions.
												<br />
												<br />
												Easy Loan America only does business with third parties
												if it is certain that those third parties have the
												required policies, procedures, and processes in place to
												comply with our strict security and privacy
												requirements.
												<br />
												<br />
												<p>
													<strong>&bull; Privacy Notice</strong>
												</p>
												Please see Easy Loan America's Official Privacy
												Notifications for more detail about how we use personal
												information and how you can opt out of sharing it if you
												want to.
												<br />
												<br />
												<p>
													<strong>
														&bull; Compliance with State and Federal Laws
													</strong>
												</p>
												Easy Loan America is aware of and complies with a
												variety of federal and state laws and regulations
												governing your privacy rights. Easy Loan America's rules
												and this website's details on your privacy rights do not
												cover everything we do to protect your privacy. You will
												also need to contact the relevant state agency in charge
												of enforcing existing state law relating to privacy
												privileges, as these rights vary from state to state.
												Please be aware that our record-keeping procedures and
												the length of time we are entitled to keep your personal
												details are subject to federal and state regulations.
												<br />
												<br />
												<p>
													<strong>&bull; Secure System</strong>
												</p>
												To help secure personally identifying information that
												you have provided to us from deletion, misuse, or
												unauthorised alteration, we use industry-recognized
												security safeguards. The information provided to Easy
												Loan America is protected by a code that encrypts it.
												Among other stuff, we use:
												<br />
												<br />
												SSL (Secure Socket Layer): Simple Loan The Secure Socket
												Layer (SSL) transaction protocol is commonly recognised
												as an industry standard by America's Web server. This
												security protocol ensures that data sent back and forth
												between a Web server and its users remains private.
												<br />
												<br />
												256-Bit Domestic Grade Strong Encryption: We use 256-Bit
												Domestic Grade Strong Encryption, which is a common
												encryption technique for Internet devices at the moment.
												Mozilla, Apple, and Microsoft all provide free 256-bit
												browser downloads.
											</div>
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-3'>
											Site Access
										</button>
									</h2>
									<div
										id='faq-content-3'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											You can use the Google Maps App by visiting our website.
											By accessing and using our website or services, you agree
											to Google's Privacy Policy, which is automatically
											incorporated herein. Easy Loan America will be able to
											access your geolocation information thanks to our Google
											Maps API integration and your approval. This information
											is gathered and used to assist you in filling out the
											address field on our website, which includes a list of
											location prediction tools. This geolocation information
											will be kept indefinitely until it is obtained. You can,
											at any point, revoke your consent to this access through
											your Internet browser. Easy Loan America takes no
											responsibility for the substance of the Google Privacy
											Policy.
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-4'>
											Data Partners
										</button>
									</h2>
									<div
										id='faq-content-4'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											Easy Loan America has formed partnerships with some data
											partners in order to provide you with an online loan
											process ("Data Partners").Data Partners may encourage Easy
											Loan America or its financing partners to verify your
											identity or confirm your financing status for
											administrative purposes, among other things. For example,
											we can check your income and other details on your bank
											account(s) from time to time using the following Data
											Partners:
											<br />
											<br />
											<p>&bull; Finicity Corporation ("Finicity")</p>
											<p>
												&bull; Clarilogic, Inc. dba Decision Logic
												("DecisionLogic")
											</p>
											<p>&bull; Plaid Technologies, Inc. ("Plaid")</p>
											You agree that we can share and maintain your information
											with these Data Partners if you use our platform. Your
											name, date of birth, address, telephone number, and social
											security number can be among the information we share. You
											also grant Easy Loan America and Plaid Data Partners the
											authority to act on your behalf in viewing and
											disseminating your personal and financial details from the
											financial institution in question. You agree to our Data
											Partners and Easy Loan America transferring, saving, and
											retrieving your personal and financial details in
											accordance with their respective privacy policies.
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-5'>
											Application and Dashboard
										</button>
									</h2>
									<div
										id='faq-content-5'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											You create an account with us by applying for a loan via
											our website. When you give us your application, you can
											create a password using your email address as your
											username. By clicking the "Sign In" button at the top of
											the website, you will be able to sign into your account.
											Whether or not you qualify for a loan, you will be given
											an account. You'll be able to see the status of your loan
											application as well as any online documents you've signed,
											such as your Electronic Transfers and Disclosures Consent
											and your Credit Pull Permit, once you sign up.
											<br />
											<br />
											You'll edit your personal information, such as your
											address and phone number, using the "My Profile" function
											of "My Dashboard." We use your contact information to send
											you reminders and updates about your loan. If you applied
											for a loan and have not received an email from us, please
											check your spam or junk folder to ensure the letter was
											not filtered.
											<br />
											<br />
											It is important that you protect your password, and if you
											believe it has been compromised, you must contact us as
											soon as possible to ensure your privacy is protected. We
											will tell you the "Password Power" when you build a
											password on our website.We recommend choosing a password
											that is stronger than "Strong" or "Very Strong."
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-6'>
											Cookies
										</button>
									</h2>
									<div
										id='faq-content-6'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											Cookies and other modern web technologies are used to help
											us manage our website.
											<br />
											<br />
											When you visit our website, a "cookie" is a small piece of
											information that our server sends to your machine. Our
											website sends cookies to your browser that contain a
											unique identifier that helps us understand how people use
											the site as a whole and individually, so we can figure out
											which sections of the site they prefer (for example, based
											on the number of visits to those areas). This is
											accomplished using a monitoring tool that, for example,
											allows you to restore session or user behaviour for
											troubleshooting and resolution purposes. Easy Loan America
											also has the option of hiring service providers to assist
											us in gathering and interpreting the data we use on our
											website
											<br />
											<br />
											When website users are invited to participate in periodic
											surveys, cookies are used to prevent multiple invitations
											to the same individual. If you are a registered user, you
											will receive a cookie when you log in. This cookie
											contains your identifier and is stored in your browser.
											This cookie is also used to verify your identity and
											provide you with access to areas of our website that are
											only accessible to registered users, such as those that
											enable you to view and update your personal profile.
											<br />
											<br />
											If you just want to browse, you don't have to accept
											cookies from our pages. However, if you want to register
											and sign in to specific sections of the website and your
											browser settings have been updated to prevent cookies from
											being accepted, you will need to reset your browser to
											accept the cookies we sent. You wouldn't be able to
											communicate with certain areas of the internet otherwise.
											By default, most browsers accept and store cookies.
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-7'>
											Data Collection and Data Sharing
										</button>
									</h2>
									<div
										id='faq-content-7'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											These are the types of data we might collect about you:
											<br />
											<br />
											<p>» Identifiers</p>
											<p>
												» Any personal data described in subdivision (e) of
												Section 1798.80 of the California Consumer Privacy Act
											</p>
											<p>
												» Characteristics of protected classifications under
												California or federal law
											</p>
											<p>» Commercial data</p>
											<p>
												» Biometric data (for employees only as required by law)
											</p>
											<p>
												» Internet or other electronic network activity data
											</p>
											<p>» Geolocation data</p>
											<p>» Sensory data</p>
											<p>» Professional or employment data</p>
											<p>» Education data</p>
											<p>
												» Inferences drawn from any of the data used to create a
												customer profile
											</p>
											<p>
												<strong>• Sources of collected data:</strong>
											</p>
											<p>
												» Information gathered via our websites » When you
												purchased a product or service from us, you gave us your
												personal information.
											</p>
											<p>
												» When you signed up for our emails, you sent us your
												email address. » When you join promotions or
												sweepstakes, you must provide certain details.
											</p>
											<p>
												» You gave us information through telephone or web-based
												surveys, online chat, customer service correspondence,
												or general feedback.
											</p>
											<p>
												» When you applied for a job with us, you provided us
												with the following details.
											</p>
											<p>
												» Third-party sources of data, such as lead buy
												partners, data brokers, and credit bureaus.
											</p>
											<p>
												» Information gathered by the use of web technologies.
											</p>
											<p>
												<strong>• Ways we might use your data:</strong>
											</p>
											<p>» Marketing (unless you've opted out)</p>
											<p>» Auditing</p>
											<p>» Fraud detection</p>
											<p>» Debugging</p>
											<p>» Providing services</p>
											<p>» Quality control</p>
											<p>
												<strong>
													• Reasons we might share your data with third parties:
												</strong>
											</p>
											<p>» Business purposes</p>
											<p>» Auditing</p>
											<p>» Detecting fraud</p>
											<p>» Debugging</p>
											<p>» Providing service</p>
											<p>» Internal research</p>
											<p>» Quality control</p>
											<p>
												<strong>
													• With your consent, we might share your personal data
													with third parties, including our affiliates, mortgage
													partners and service providers, for these reasons:
												</strong>
											</p>
											<p>» Marketing</p>
											<p>» Analytics</p>
											<p>
												» Pre-populating your information to make your
												experience easier
											</p>
										</div>
									</div>
								</div>
								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-8'>
											California Residents: Sections 1.1 - 1.9 Detail your
											rights under the california consumer privacy act
										</button>
									</h2>
									<div
										id='faq-content-8'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											<p>
												<strong>• 1.1 Sale of Data</strong>
											</p>
											<p>
												We can sell your personal information to trusted
												business partners or affiliates. You have the right to
												refuse the selling of your personal information to third
												parties.
											</p>

											<p>
												<strong>• 1.2 Your privacy rights</strong>
											</p>
											<p>
												Please contact us if you'd like to check, access,
												revise, or delete the personal data we've collected
												about you.
											</p>

											<p>
												<strong>• 1.3 Right to Know</strong>
											</p>
											<p>
												You have the right to know what sensitive information we
												have about you, what data we've sold, who we've sold it
												to, and what data we've shared for business purposes.
											</p>

											<p>
												<strong>• 1.4 Right to Access</strong>
											</p>
											<p>
												You have the right to know what personal information we
												have about you and to have that information transferred
												to you in a compact and easily accessible format.
											</p>

											<p>
												<strong>• 1.5 Right to Delete</strong>
											</p>
											<p>
												You have the right to request that your data be deleted.
												We're allowed by law to retain some information about
												you, but we'll delete everything else. Keep in mind that
												if we buy data from a third party, we could get some of
												your information back.
											</p>

											<p>
												<strong>
													• 1.6 How We Process Your Privacy Rights
												</strong>
											</p>
											<p>
												First, we'll use your personal information to confirm
												your identity. Then we'll handle your request in
												accordance with company policies. Within 45 days, you
												should receive an answer.
											</p>

											<p>
												<strong>• 1.7 Authorized Agent</strong>
											</p>
											<p>
												Please contact us if you're making a request on behalf
												of someone else as an approved agent (for example, a
												power of attorney).
											</p>

											<p>
												<strong>
													• 1.8 Right to Non-Discrimination For The Exercise Of
													Your Privacy Rights
												</strong>
											</p>
											<p>
												You will not be treated unfairly if you exercise your
												right to privacy.You will not be treated unfairly if you
												exercise your right to privacy.
											</p>

											<p>
												<strong>• 1.9 How to Contact Us About Your Data</strong>
											</p>
											<p>
												Call us at +1 336-501-4510
												<br />
												Send an email to Support@EasyLoanAmerica.com
												<br />
												log into your account
											</p>
										</div>
									</div>
								</div>

								<div className='accordion-item'>
									<h2 className='accordion-header'>
										<button
											className='accordion-button collapsed'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#faq-content-9'>
											Changes to Security and Privacy practices
										</button>
									</h2>
									<div
										id='faq-content-9'
										className='accordion-collapse collapse'
										data-bs-parent='#faqlist1'>
										<div className='accordion-body'>
											<p>
												We will change our policies from time to time to better
												protect you because we monitor security and privacy
												issues and best practises on a regular basis. You will
												be able to inform us that this page has changed
												depending on the effective date below if our policies
												change and it is appropriate to change this page. Please
												email us at Support@EasyloanAmerica.com for more
												information, to share any comments or concerns about our
												privacy policy, or to access previous versions of this
												website.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End F.A.Q Section */}
		</>
	);
};

export default PrivacyPolicy;
