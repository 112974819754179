import React from 'react';

const LoanCreditCard = () => {
	return (
		<>
			{/* ======= Loan credit card Section ======= */}
			<section id='services' className='services'>
				<div className='container' data-aos="fade-up">
					<header className='section-header'>
						<p>Getting a House Financed vs. Using a Credit Card</p>
					</header>
					<div className='row gy-4'>
						<div className='col-lg-6 col-md-6' data-aos="fade-up" data-aos-delay="200">
							<div className='service-box container'>
								<div className='service-box-green-header'>
									<p className='font-weight-bold text-lg'>
										Easy Loan America Housing Finance
									</p>
								</div>
								<div className='row'>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Loan Amount :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>$5000</div>
									</div>

									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Intrest Rate :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>8.49% APR</div>
									</div>

									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Time to Pay Off :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>2 Years</div>
									</div>
								</div>
								<hr />
								<div className='row'>
									<div className='col-md-6 col-6 my-3'>
										<div className='text-start font-weight-bold text-green text-19'>
											Total monthly Payment :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold text-green text-19'>
											$244
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-6 col-md-6' data-aos="fade-up" data-aos-delay="200">
							<div className='service-box container'>
								<div className='service-box-gray-header'>
									<p className='font-weight-bold text-lg'>
										Combined Bill and Credit Card Debt
									</p>
								</div>
								<div className='row'>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Owed Amount :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>$5000</div>
									</div>

									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Intrest Rate :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>22% APR</div>
									</div>

									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-start font-weight-bold'>
											Time to Pay Off :
										</div>
									</div>
									<div className='col-md-6 col-6 my-3 amount-color'>
										<div className='text-end font-weight-bold'>2 Years</div>
									</div>
								</div>
								<hr />
								<div className='row'>
									<div className='col-md-6 col-6 my-3'>
										<div className='text-start font-weight-bold amount-color text-19'>
											Total monthly Payment :
										</div>
									</div>
									<div className='col-md-6 my-3 col-6 amount-color'>
										<div className='text-end font-weight-bold text-orange text-19'>
											$244
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Loan credit card Section */}
		</>
	);
};

export default LoanCreditCard;
