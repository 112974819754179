// Front
import Main from './views/Static/Main';
import HousingFinance from './views/Static/HousingFinance';
import EmergencyLoans from './views/Static/EmergencyLoans';
import PersonalLoans from './views/Static/PersonalLoans';
import Contact from './views/Static/ContactUs';
import About from './views/Static/AboutUs';
import ApplyForLoan from './views/Static/ApplyForLoan';
import PrivacyPolicy from './views/Static/PrivacyPolicy';

var routes = [

	/* front-end */
	{
		path: '/housing-finance',
		name: 'HousingFinance',
		component: HousingFinance,
		layout: '/front',
	},
	{
		path: '/emergency-loans',
		name: 'EmergencyLoans',
		component: EmergencyLoans,
		layout: '/front',
	},
	{
		path: '/personal-loans',
		name: 'PersonalLoans',
		component: PersonalLoans,
		layout: '/front',
	},
	{
		path: '/contact-us',
		name: 'Contact',
		component: Contact,
		layout: '/front',
	},
	{
		path: '/about-us',
		name: 'About',
		component: About,
		layout: '/front',
	},
	{
		path: '/apply-for-loan',
		name: 'ApplyForLoan',
		component: ApplyForLoan,
		layout: '/front',
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
		layout: '/front',
	},
	{
		path: '/calculate',
		name: 'Home',
		component: Main,
		layout: '/front',
	},
	{
		path: '/',
		name: 'Home',
		component: Main,
		layout: '/front',
	},
];
export default routes;
