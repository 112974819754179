import React from 'react';

import Benefits from './includes/Benefits';
import LoanCreditCard from './includes/LoanCreditCard';
import ImageDisclaimer from './includes/ImageDisclaimer';
import CalculatePayment from './includes/CalculatePayment';

const PersonalLoans = () => {
	return (
		<>
			{/* ======= Hero Section ======= */}
			<section id='hero' className='d-flex' style={{ padding: '0px' }}>
				<div className='container-fluid px-0'>
					<div className='row'>
						<div
							className='col-lg-12 housing-hero-img'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								width={'100%'}
								src={
									require('../../assets/front_assets/assets/img/personal_loan.png')
										.default
								}
								className='img-fluid img-responsive'
								alt=''
							/>
							<div className='caption col-lg-6 col-sm-12 col-md-12 d-flex flex-column justify-content-center'>
								<h1 data-aos='fade-up' className='header-text'>
									Get a personal loan.
								</h1>
								<div className='line' data-aos='fade-up'></div>
								<h5
									style={{ paddingTop: '5px' }}
									className='header-text-mini'
									data-aos='fade-up'
									data-aos-delay={400}>
									$2000 - $35,000. from 8.99% APR. Pre-qualify online in
									seconds.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Hero */}

			<CalculatePayment />

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12'>
						<h2 className='text-green static-content' data-aos='fade-up'>
							What is a Personal Loan ?
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={400}>
							A personal loan is typically a loan that ranges from $2,000 to
							$35,000, no collateral or equity required.
							<br />
							<br />
							Whether you need quick cash to cover the cost of an unexpected
							expense such as a medical bill, or to finance an emergency car or
							home repair, a personal loan from Easy Loan America can deliver
							the funds to your account as soon as the next business day.
							<br />
							<br />
							Compared with payday loans or footing the bill with your credit
							card, this type of loan tends to come with a lower interest rate
							that won’t change and no annual fees, which also means lower
							monthly payments and more cash flow for you.
						</h5>
					</div>

					<div className='col-md-12 col-12 mt-5'>
						<h2
							className='text-green static-content'
							data-aos='fade-up'
							data-aos-delay={400}>
							How does a Personal loans works?
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={600}>
							A small personal loan is an unsecured loan that’s tied to factors
							like your creditworthiness and income, among others - yet not
							collateral, like your home or car.
							<br />
							<br />
							It can be simple to pre-qualify: just fill out Easy Loan America’s
							online application with your basic information, check your rates,
							and choose from the loan options available to you.
							<br />
							<br />
							If the application is approved, you’ll receive your loan amount in
							your account in one lump sum, and then all you need to do is make
							your appointed loan payments each month. The payment amount won’t
							change because personal loans are fixed-rate loans, and with Easy
							Loan America, there aren’t any hidden fees either.
						</h5>
					</div>
				</div>
			</div>

			<Benefits getFastCash lowInterestRate noAnnualFees />

			<LoanCreditCard />

			{/* Compare loan */}

			<section id='about' className='why-choose-us'>
				<div className='container' data-aos='fade-up'>
					<header className='section-header'>
						<p>Compare the Best Personal Loan</p>
					</header>
					<div className='row gx-0'>
						<div
							style={{ justifyContent: 'center' }}
							className='col-lg-12 d-flex align-items-center'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								src={
									require('../../assets/front_assets/assets/img/personal_compare.png')
										.default
								}
								className='img-fluid'
								alt=''
							/>
						</div>
					</div>
				</div>
			</section>

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12 text-center'>
						<h5
							className='text-green font-weight-bold'
							data-aos='fade-up'
							data-aos-delay={400}>
							Reduce your monthly payment by $56 and save a total of
							<br />
							$1344 on interest over the 24-month period.
						</h5>
						<p
							className='mt-3 small-content'
							data-aos='fade-up'
							data-aos-delay={600}>
							* Interest rate and savings estimates based on an applicant with
							very good credit
							<br />
							<br />
							Base interest rate starting from 8.99% APR to 21.49% APR. The APR
							is determined based on your credit score and history. Available
							<br />
							terms from 24 to 60 months, depending on the loan amount. Example:
							An unsecured personal loan of $5,000 with an 11.49% APR for
							<br /> 24 months would pay $234 monthly. Other terms and
							conditions available.
						</p>
					</div>
				</div>
			</div>

			<ImageDisclaimer />
		</>
	);
};

export default PersonalLoans;
