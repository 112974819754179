import React, { useState, useContext } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useHistory } from 'react-router-dom';
import MainContexts from '../../../contexts/MainContexts';

const CalculatePayment = () => {
    const [value, setValue] = useState(1000);
    const [loanValue, setloanValue] = useState(1);
    const [credit, setCredit] = useState(0);
    const [finalCreditScore, setFinalCreditScore] = useState('N/A');
    const history = useHistory();
    const contextData = useContext(MainContexts);
    const passDataToLoan = (e) => {
        e.preventDefault();
        contextData[0].setContextData((item) => {
            return [{ value, loanValue, finalCreditScore }];
        });
        history.replace('/apply-for-loan');
    };

    const setCreditValue = (value) => {
        if (parseInt(value) === 0) {
            setFinalCreditScore('Very Poor');
            return 'Very Poor';
        } else if (parseInt(value) === 5) {
            setFinalCreditScore('Poor');
            return 'Poor';
        } else if (parseInt(value) === 10) {
            setFinalCreditScore('Fair');
            return 'Fair';
        } else if (parseInt(value) === 15) {
            setFinalCreditScore('Good');
            return 'Good';
        } else if (parseInt(value) === 20) {
            setFinalCreditScore('Excellent');
            return 'Excellent';
        }
    };

    return (
        <>
            {/* ======= Why choose us Section ======= */}
            <section id="calculate-payment" className="why-choose-us" style={{ padding: '20px 0' }}>
                <div className="container-fluid px-0" data-aos="fade-up">
                    <div className="row gx-0">
                        <div
                            className="col-lg-7 d-flex flex-column justify-content-center"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="content" style={{ paddingBlock: 0 }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row" style={{ textAlign: 'center' }}>
                                            <div className="col-md-10 mt-3">
                                                <header className="section-header">
                                                    <p>Calculate Payment</p>
                                                </header>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-5">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="font-weight-bold text-gray h5">Loan Amount</span>
                                            </div>
                                            <div className="col-md-8">
                                                <RangeSlider
                                                    variant="success"
                                                    size="lg"
                                                    min={1000}
                                                    max={15000}
                                                    tooltipPlacement="top"
                                                    step={500}
                                                    value={value}
                                                    tooltip="on"
                                                    tooltipLabel={(value) => `${value} $`}
                                                    onChange={(changeEvent) => setValue(changeEvent.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="font-weight-bold text-gray h5">Term of Loan</span>
                                            </div>
                                            <div className="col-md-8">
                                                <RangeSlider
                                                    variant="success"
                                                    size="lg"
                                                    tooltipPlacement="top"
                                                    value={loanValue}
                                                    tooltip="on"
                                                    min={1}
                                                    max={8}
                                                    step={1}
                                                    tooltipLabel={(loanValue) => `${loanValue} Years`}
                                                    onChange={(changeEvent) => setloanValue(changeEvent.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="font-weight-bold text-gray h5">
                                                    Whats your Credit score?
                                                </span>
                                            </div>
                                            <div className="col-md-8">
                                                <RangeSlider
                                                    variant="success"
                                                    size="lg"
                                                    tooltipPlacement="top"
                                                    value={credit}
                                                    tooltip="on"
                                                    min={0}
                                                    max={20}
                                                    step={5}
                                                    tooltipLabel={(value) => setCreditValue(value)}
                                                    onChange={(changeEvent) => setCredit(`${changeEvent.target.value}`)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-3 col-12 mb-3">
                                                <button
                                                    onClick={(e) => passDataToLoan(e)}
                                                    className="btn btn-block btn-green-gradient"
                                                >
                                                    Apply Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-5 d-flex align-items-center"
                            data-aos="zoom-out"
                            data-aos-delay={200}
                            style={{ justifyContent: 'end' }}
                        >
                            <img
                                src={require('../../../assets/front_assets/assets/img/calculate-payment.png').default}
                                className="img-fluid"
                                alt=""
                                style={{ transform: 'scaleX(-1)' }}
                            />
                            <div
                                className="row calculate-image-text my-auto"
                                style={{
                                    width: '100%',
                                    // paddingLeft: '90px',
                                    textAlign: 'end',
                                    paddingInline: '40px 30px',
                                }}
                            >
                                {/* <br/><br/><br/> */}
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 h4 my-0">
                                            <p>
                                                Your Monthly Payment Will be :{' '}
                                                <b>
                                                    &nbsp; &nbsp;
                                                    {(
                                                        value / (12 * loanValue) +
                                                        (value * (8 * 0.01)) / (12 * loanValue)
                                                    ).toFixed(2)}
                                                </b>
                                            </p>
                                        </div>
                                        {/* <div className="col-md-12 col-sm-12 text-center my-0">
                                            <h3 className="font-weight-bold text-uppercase"></h3>
                                        </div> */}
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12 col-sm-12 h4">
                                            <p>
                                                Interest Rate : &nbsp; &nbsp;
                                                <b>8% APR</b>
                                            </p>
                                        </div>
                                        {/* <div className="col-md-7 col-sm-12">
                                            <h4 className="font-weight-bold text-uppercase">8% APR</h4>
                                        </div> */}
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 col-sm-12 h4">
                                            <p>
                                                Credit score : &nbsp; &nbsp;
                                                <b>{finalCreditScore}</b>
                                            </p>
                                        </div>
                                        {/* <div className="col-md-7 col-sm-12">
                                            <h4 className="font-weight-bold text-uppercase">{finalCreditScore}</h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Why choose us Section */}
        </>
    );
};

export default CalculatePayment;
