import React, { useState } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../assets/front_assets/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/front_assets/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/front_assets/assets/vendor/aos/aos.css';
import '../assets/front_assets/assets/vendor/remixicon/remixicon.css';
import '../assets/front_assets/assets/vendor/swiper/swiper-bundle.min.css';
import '../assets/front_assets/assets/vendor/glightbox/css/glightbox.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Header from '../views/Static/includes/Header';
import Footer from '../views/Static/includes/Footer';
import routes from '../routes.js';
import MainContext from '../contexts/MainContexts';

const MainLayout = () => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	const [visible, setVisible] = useState(false);

	const [contextData, setContextData] = useState([]);
	const value = [{ contextData, setContextData }];

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = (e) => {
		e.preventDefault();
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', toggleVisible);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/front') {
				require('../assets/front_assets/assets/css/style.css');
				return <Route path={prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<MainContext.Provider value={value}>
				<ToastContainer
					position='top-right'
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover
				/>
				<div style={{ display: 'none' }} className='spinner'></div>
				<div id='main-content' ref={mainContent}>
					<Header />
					{/* <Main /> */}
					<Switch>
						{getRoutes(routes)}
						<Redirect from='*' to='/' />
					</Switch>
					<Footer />
					<a
						href='#!'
						onClick={(e) => scrollToTop(e)}
						className={`back-to-top d-flex align-items-center justify-content-center ${
							visible ? 'active' : ''
						}`}>
						<i className='bi bi-arrow-up-short' />
					</a>
				</div>
			</MainContext.Provider>
		</>
	);
};

export default MainLayout;
