import React from 'react';

const WhyChooseUs = () => {
    return (
        <>
            {/* ======= Why choose us Section ======= */}
            <section id="about" className="why-choose-us">
                <div className="container-fluid px-0" data-aos="fade-up">
                    <div className="row gx-0">
                        <div className="col-lg-7 d-flex align-items-center" data-aos="zoom-out" data-aos-delay={200}>
                            <img
                                src={
                                    require('../../../assets/front_assets/assets/img/why-choose-us/why-choose-us.png')
                                        .default
                                }
                                className="img-fluid-banner"
                                alt=""
                            />
                            <div className="row image-text">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-8 ml-4">
                                            <h2 className="text-underline font-weight-bold text-uppercase">Why</h2>
                                        </div>
                                        <div className="col-md-8 ml-8">
                                            <h2 className="text-underline font-weight-bold text-uppercase">Choose</h2>
                                        </div>
                                        <div className="col-md-8 ml-10">
                                            <h2 className="text-underline font-weight-bold text-uppercase">Us ?</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>We provide fast affordable financing that meets your needs.</p>
                                        </div>
                                    </div>
                                    <div className="row ml-3">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <i
                                                        className="bi bi-check float-left"
                                                        style={{ fontSize: '35px' }}
                                                    ></i>
                                                </div>
                                                <div className="col-md-9 col-9">
                                                    <p className="mt-3">RATES STARTING AT 8.99% APR</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <i
                                                        className="bi bi-check float-left"
                                                        style={{ fontSize: '35px' }}
                                                    ></i>
                                                </div>
                                                <div className="col-md-9 col-9">
                                                    <p className="mt-3">CREDIT DECISION IN 1HR</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <i
                                                        className="bi bi-check float-left"
                                                        style={{ fontSize: '35px' }}
                                                    ></i>
                                                </div>
                                                <div className="col-md-9 col-9">
                                                    <p className="mt-3">MONEY FAST Next business day disbursement</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-5 d-flex flex-column justify-content-center"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img
                                                    src={
                                                        require('../../../assets/front_assets/assets/img/why-choose-us/no-upfront-amount.png')
                                                            .default
                                                    }
                                                    width="50px"
                                                    height="50px"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-10 mt-3">
                                                <h3 className="font-weight-bold text-gray">No Upfront - Amount</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img
                                                    src={
                                                        require('../../../assets/front_assets/assets/img/why-choose-us/no-insurance.png')
                                                            .default
                                                    }
                                                    width="50px"
                                                    height="50px"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-10 mt-3">
                                                <h3 className="font-weight-bold text-gray">No Insurance</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img
                                                    src={
                                                        require('../../../assets/front_assets/assets/img/why-choose-us/no-paperwork.png')
                                                            .default
                                                    }
                                                    width="50px"
                                                    height="50px"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-10 mt-3">
                                                <h3 className="font-weight-bold text-gray">No paperwork</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img
                                                    src={
                                                        require('../../../assets/front_assets/assets/img/why-choose-us/no-need-to-wait.png')
                                                            .default
                                                    }
                                                    width="50px"
                                                    height="50px"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-10 mt-3">
                                                <h3 className="font-weight-bold text-gray">No Need to Wait</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img
                                                    src={
                                                        require('../../../assets/front_assets/assets/img/why-choose-us/no-high-rates.png')
                                                            .default
                                                    }
                                                    width="50px"
                                                    height="50px"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-10 mt-3">
                                                <h3 className="font-weight-bold text-gray">No to High to Wait</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Why choose us Section */}
        </>
    );
};

export default WhyChooseUs;
