import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getToken } from 'services/dataService';
import jwtDecode from 'jwt-decode';
import { logout } from 'services/dataService';

const Header = () => {
    const [toggleClass, setToggleClass] = useState(false);
    const history = useHistory();
    const mobileNavToggle = (e) => {
        e.preventDefault();
        setToggleClass((toggleClass) => !toggleClass);
    };
    const tokenData = getToken() && jwtDecode(getToken());

    const linkForWhiteBackground =
        history.location.pathname.endsWith('about-us') ||
        history.location.pathname.endsWith('apply-for-loan') ||
        history.location.pathname.endsWith('contact-us') ||
        history.location.pathname.endsWith('privacy-policy')
            ? 'white-background'
            : '';

    const handleRedirection = (e, path) => {
        e.preventDefault();
        setToggleClass(false);
        if (path === '/calculate') {
            history.replace(path);
        } else {
            history.push(path);
        }
    };

    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to="/" className={`logo d-flex align-items-center`}>
                        <img src={require('../../../assets/front_assets/assets/img/logo.png').default} alt="" />
                    </Link>
                    <nav id="navbar" className={`navbar ${toggleClass ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li className="dropdown">
                                <a href="#!" onClick={(e) => e.preventDefault()} className={`${linkForWhiteBackground}`}>
                                    <span>Loans</span> <i className="bi bi-chevron-down" />
                                </a>
                                <ul>
                                    <li>
                                        <a
                                            href="#!"
                                            onClick={(e) => handleRedirection(e, '/housing-finance')}
                                            className={`${linkForWhiteBackground}`}
                                        >
                                            Housing Finance
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#!"
                                            onClick={(e) => handleRedirection(e, '/emergency-loans')}
                                            className={`${linkForWhiteBackground}`}
                                        >
                                            Emergency Loans
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#!"
                                            onClick={(e) => handleRedirection(e, '/personal-loans')}
                                            className={`${linkForWhiteBackground}`}
                                        >
                                            Personal Loans
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    href="#!"
                                    onClick={(e) => handleRedirection(e, '/calculate')}
                                    className={`${linkForWhiteBackground} nav-link scrollto active`}
                                >
                                    Calculator & Tools
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#!"
                                    onClick={(e) => handleRedirection(e, '/about-us')}
                                    className={`${linkForWhiteBackground} nav-link scrollto`}
                                >
                                    For Assistance
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#!"
                                    onClick={(e) => handleRedirection(e, '/')}
                                    className={`${linkForWhiteBackground} nav-link scrollto`}
                                >
                                    Customer Review
                                </a>
                            </li>
                            {!getToken() && (
                                <li>
                                    <a
                                        href="#!"
                                        onClick={() => {
                                            window.location.replace('https://eazyloanamerica.com/dashboard');
                                        }}
                                        className="getstarted scrollto"
                                    >
                                        <i className="bi bi-box-arrow-in-right login-icon" />
                                        Login
                                    </a>
                                </li>
                            )}
                            {getToken() && (
                                <li className="dropdown">
                                    <Link to="/" className={`${linkForWhiteBackground}`}>
                                        <span>
                                            Hi{', '}
                                            {tokenData.user.firstName + ' ' + tokenData.user.lastName}
                                        </span>{' '}
                                        <i className="bi bi-chevron-down" />
                                    </Link>
                                    <ul>
                                        <li>
                                            <a
                                                href="#!"
                                                onClick={() => {
                                                    window.location.replace('https://eazyloanamerica.com/dashboard');
                                                }}
                                                className={`${linkForWhiteBackground}`}
                                            >
                                                Dashboard
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" onClick={() => logout()} className={`${linkForWhiteBackground}`}>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                        <i className={`bi ${!toggleClass ? 'bi-list' : 'bi-x'} mobile-nav-toggle`} onClick={(e) => mobileNavToggle(e)} />
                    </nav>
                    {/* .navbar */}
                </div>
            </header>
            {/* End Header */}
        </>
    );
};

export default Header;
