import React from 'react';

import Benefits from './includes/Benefits';
import LoanCreditCard from './includes/LoanCreditCard';
import ImageDisclaimer from './includes/ImageDisclaimer';
import CalculatePayment from './includes/CalculatePayment';

const HousingFinance = () => {
	return (
		<>
			{/* ======= Hero Section ======= */}
			<section id='hero' className='d-flex' style={{ padding: '0px' }}>
				<div className='container-fluid px-0'>
					<div className='row'>
						<div
							className='col-lg-12 housing-hero-img'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								width={'100%'}
								src={
									require('../../assets/front_assets/assets/img/housing-min.png')
										.default
								}
								className='img-fluid img-responsive'
								alt=''
							/>
							<div className='caption col-lg-6 col-sm-12 col-md-12 d-flex flex-column justify-content-center'>
								<h1 data-aos='fade-up' className='header-text'>
									House Financing
								</h1>
								<h1 data-aos='fade-up' className='header-text'>
									for Large Living. No
								</h1>
								<h1 data-aos='fade-up' className='header-text'>
									Down Payment Required.
								</h1>
								<div className='line' data-aos='fade-up'></div>
								<h5
									style={{ paddingTop: '5px' }}
									className='header-text-mini'
									data-aos='fade-up'
									data-aos-delay={400}>
									Low Rates. No Origination Fees
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Hero */}

			<CalculatePayment />

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12'>
						<h2 className='text-green static-content' data-aos='fade-up'>
							Untraditional personal loans for Untraditional houses
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={400}>
							You’ve chosen to live your life differently. So why overcomplicate
							a simpler existence with complex financing?
							<br />
							<br />
							An Easy Loan America’s personal loan can be used for a house and
							can be the fastest, easiest way to secure the money you need to
							get the home you want.
							<br />
							<br />
							Whether you’re looking to buy a house starter kit, construct a
							mini cabin for mountain adventures, or make your own dream
							bungalow, you can use an Easy Loan America personal loan to pay
							for the necessary resources and construction materials.
							<br />
							<br />
							No down payment, appraisal, or RVIA certification required.
						</h5>
					</div>

					<div className='col-md-12 col-12 mt-5'>
						<h2
							className='text-green static-content'
							data-aos='fade-up'
							data-aos-delay={400}>
							How does personal loans for a Home works?
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={600}>
							Getting financing for a home is usually no small affair, but in
							the case it’s surprisingly easy.
							<br />
							<br />
							That’s because it’s actually not possible to get a formal mortgage
							from most lenders, as the cost of a home is below most banks’
							mortgage minimum requirements.
							<br />
							<br />
							That’s because it’s actually not possible to get a formal mortgage
							from most lenders, as the cost of a home is below most banks’
							mortgage minimum requirements.
							<br />
							<br />
							This kind of loan is an unsecured loan, which means it doesn’t
							require a down payment or any kind of collateral, like home
							equity, to qualify. Rather, lenders evaluate borrowers based on
							factors like credit score and employment status, among others, to
							decide how likely they’ll be to make their monthly repayments on
							time.
							<br />
							<br />
							It’s also entirely up to you how you use the money from a personal
							loan once you receive it. There are no restrictions — it gives you
							the power to cover any house construction cost under the sun.
							<br />
							<br />
							After you’re approved, the cash will be deposited in your bank
							account all at once, and then you just need to make repayments for
							the money you borrowed in regular monthly installments. You choose
							the terms of your repayment period, and you can also rest easy
							knowing that personal loans are fixed rate loans, so the amount
							you pay each month won’t change.
						</h5>
					</div>
				</div>
			</div>

			<Benefits
				getFastCash
				lowInterestRate
				noAnnualFees
				noDownPayment
				flexiblePayment
			/>

			<LoanCreditCard />

			{/* Compare loan */}

			<section id='about' className='why-choose-us'>
				<div className='container' data-aos='fade-up'>
					<header className='section-header'>
						<p>Compare the Best Personal Loan</p>
					</header>
					<div className='row gx-0'>
						<div
							style={{ justifyContent: 'center' }}
							className='col-lg-12 d-flex align-items-center'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								src={
									require('../../assets/front_assets/assets/img/house_compare.png')
										.default
								}
								className='img-fluid'
								alt=''
							/>
						</div>
					</div>
				</div>
			</section>

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12 text-center'>
						<h5
							className='text-green font-weight-bold'
							data-aos='fade-up'
							data-aos-delay={400}>
							Reduce your monthly payment by $56 and save a total of
							<br />
							$1344 on interest over the 24-month period.
						</h5>
						<p
							className='mt-3 small-content'
							data-aos='fade-up'
							data-aos-delay={600}>
							* Interest rate and savings estimates based on an applicant with
							very good credit
							<br />
							<br />
							Base interest rate starting from 8.99% APR to 21.49% APR. The APR
							is determined based on your credit score and history. Available
							<br />
							terms from 24 to 60 months, depending on the loan amount. Example:
							An unsecured personal loan of $5,000 with an 11.49% APR for
							<br /> 24 months would pay $234 monthly. Other terms and
							conditions available.
						</p>
					</div>
				</div>
			</div>

			<ImageDisclaimer />
		</>
	);
};

export default HousingFinance;
