import React from 'react';

const Benefits = ({
	getFastCash = false,
	lowInterestRate = false,
	noAnnualFees = false,
	noDownPayment = false,
	flexiblePayment = false,
}) => {
	return (
		<>
			{/* ======= Values Section ======= */}
			<section id='values' className='values'>
				<div className='container' data-aos='fade-up'>
					<header className='section-header'>
						<p>Benefits of an House Financing from Easy Cash America</p>
					</header>
					<div className='row'>
						{getFastCash && (
							<div className='col-lg-4'>
								<div className='box' data-aos='fade-up' data-aos-delay={200}>
									<img
										src={
											require('../../../assets/front_assets/assets/img/benefits/get-fast-cash.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
									<h3>Get Fast Cash</h3>
									<p>
										Pre-qualify in minutes and get funds in your account as
										early as the next business day.
									</p>
								</div>
							</div>
						)}
						{lowInterestRate && (
							<div className='col-lg-4 mt-4 mt-lg-0'>
								<div className='box' data-aos='fade-up' data-aos-delay={400}>
									<img
										src={
											require('../../../assets/front_assets/assets/img/benefits/low-interest-rate.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
									<h3>Low Interest Rate</h3>
									<p>
										Pay less in interest than with payday loans or credit card
										use.
									</p>
								</div>
							</div>
						)}{' '}
						{noAnnualFees && (
							<div className='col-lg-4 mt-4 mt-lg-0'>
								<div className='box' data-aos='fade-up' data-aos-delay={600}>
									<img
										src={
											require('../../../assets/front_assets/assets/img/benefits/no-annual-fees.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
									<h3>No Annual Fees</h3>
									<p>Zero origination fees. Zero prepayment penalties.</p>{' '}
								</div>
							</div>
						)}{' '}
						{noDownPayment && (
							<div className='col-lg-4 mt-4 mt-lg-5' style={{marginLeft: 'auto'}}>
								<div className='box' data-aos='fade-up' data-aos-delay={600}>
									<img
										src={
											require('../../../assets/front_assets/assets/img/benefits/no-down-payment.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
									<h3>No Down Payment Necessary</h3>
									<p>
										Prequalify in minutes by completing our easy online
										application
									</p>
								</div>
							</div>
						)}
						{flexiblePayment && (
							<div className='col-lg-4 mt-4 mt-lg-5' style={{marginRight: 'auto'}}>
								<div className='box' data-aos='fade-up' data-aos-delay={600}>
									<img
										src={
											require('../../../assets/front_assets/assets/img/benefits/flexible-payment.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
									<h3>Flexible Payment Terms</h3>
									<p>
										Customize your payback period, from 24 months to 5 years
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
			{/* End Values Section */}
		</>
	);
};

export default Benefits;
