import React from 'react';

import Benefits from './includes/Benefits';
import LoanCreditCard from './includes/LoanCreditCard';
import ImageDisclaimer from './includes/ImageDisclaimer';
import CalculatePayment from './includes/CalculatePayment';

const EmergencyLoans = () => {
	return (
		<>
			{/* ======= Hero Section ======= */}
			<section id='hero' className='d-flex' style={{ padding: '0px' }}>
				<div className='container-fluid px-0'>
					<div className='row'>
						<div
							className='col-lg-12 housing-hero-img'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								width={'100%'}
								src={
									require('../../assets/front_assets/assets/img/emergency_loan.png')
										.default
								}
								className='img-fluid img-responsive'
								alt=''
							/>
							<div className='caption col-lg-6 col-sm-12 col-md-12 d-flex flex-column justify-content-center'>
								<h1 data-aos='fade-up' className='header-text'>
									Emergency Loan
								</h1>
								<h1 data-aos='fade-up' className='header-text'>
									money for your
								</h1>
								<h1 data-aos='fade-up' className='header-text'>
									emergency expenses.
								</h1>
								<div className='line' data-aos='fade-up'></div>
								<h5
									style={{ paddingTop: '5px' }}
									className='header-text-mini'
									data-aos='fade-up'
									data-aos-delay={400}>
									Low Rates. No Origination Fees
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Hero */}

			<CalculatePayment />

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12'>
						<h2 className='text-green static-content' data-aos='fade-up'>
							What is an Emergency Loan ?
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={400}>
							An emergency loan is a personal loan that offers a smart and
							effective way to cover unplanned and completely unpredictable
							costs and expenses. After all, you can’t budget for everything —
							and no matter how much you work to expect the unexpected,
							sometimes there’s simply no way to know what’s ahead.
							<br />
							<br />
							This type of loan can give you the peace of mind that matters when
							a financial shock would otherwise leave you struggling and
							stressed out. Easy Loan America emergency loans come with quick
							approval times, low interest rates, no annual or origination fees,
							and a simply flexible loan payment schedule.
						</h5>
					</div>

					<div className='col-md-12 col-12 mt-5'>
						<h2
							className='text-green static-content'
							data-aos='fade-up'
							data-aos-delay={400}>
							How does emergency loans works ?
						</h2>
						<h5 className='mt-3' data-aos='fade-up' data-aos-delay={600}>
							An emergency loan is an unsecured personal loan that gives you the
							power to pay for unforeseen expenses immediately. If approved,
							you’ll get the money in your account as soon as the next business
							day.
							<br />
							<br />
							Then, instead of using a credit card to cover costs and juggling
							multiple credit card minimums with high interest rates, you just
							make simple fixed-rate repayments based on the terms you choose.
							<br />
							<br />
							Personal loans tend to come with lower rates than credit cards and
							no annual fees, so while you’ll still need to pay back the money
							you borrowed, you can do it in a smarter, more affordable way.
						</h5>
					</div>
				</div>
			</div>

			<Benefits getFastCash lowInterestRate noAnnualFees />

			<LoanCreditCard />

			{/* Compare loan */}

			<section id='about' className='why-choose-us'>
				<div className='container' data-aos='fade-up'>
					<header className='section-header'>
						<p>Compare the Best Emergency Loan</p>
					</header>
					<div className='row gx-0'>
						<div
							style={{ justifyContent: 'center' }}
							className='col-lg-12 d-flex align-items-center'
							data-aos='zoom-out'
							data-aos-delay={200}>
							<img
								src={
									require('../../assets/front_assets/assets/img/emergency_compare.png')
										.default
								}
								className='img-fluid'
								alt=''
							/>
						</div>
					</div>
				</div>
			</section>

			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-12 col-12 text-center'>
						<h5
							className='text-green font-weight-bold'
							data-aos='fade-up'
							data-aos-delay={400}>
							Reduce your monthly payment by $56 and save a total of
							<br />
							$1344 on interest over the 24-month period.
						</h5>
						<p
							className='mt-3 small-content'
							data-aos='fade-up'
							data-aos-delay={600}>
							* Interest rate and savings estimates based on an applicant with
							very good credit
							<br />
							<br />
							Base interest rate starting from 8.99% APR to 21.49% APR. The APR
							is determined based on your credit score and history. Available
							<br />
							terms from 24 to 60 months, depending on the loan amount. Example:
							An unsecured personal loan of $5,000 with an 11.49% APR for
							<br /> 24 months would pay $234 monthly. Other terms and
							conditions available.
						</p>
					</div>
				</div>
			</div>

			<ImageDisclaimer />
		</>
	);
};

export default EmergencyLoans;
