import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { createContactAPI } from '../../services/api';

const ContactUs = () => {
	const formFields = {
		contactName: '',
		contactEmail: '',
		contactSubject: '',
		contactMessage: '',
	};
	const validationSchema = Yup.object().shape({
		contactName: Yup.string().required('Name is required'),
		contactEmail: Yup.string()
			.email('Email is invalid')
			.required('Email is required'),
		contactSubject: Yup.string().required('Subject is required'),
		contactMessage: Yup.string().required('Message is required'),
	});

	var addContact = (data, resetForm) => {
		createContactAPI(data).then((result) => {
			if (result && result.success) {
				toast.success(result.message);
				resetForm();
			} else {
				toast.error(result.message);
			}
		});
	};

	return (
		<>
			<main id='main'>
				{/* ======= Contact Section ======= */}
				<section id='contact' className='contact mt-5'>
					<div className='container' data-aos='fade-up'>
						<header className='section-header'>
							<p>Contact Us</p>
						</header>
						<div className='row gy-4'>
							<Formik
								onSubmit={(values, { resetForm }) => {
									addContact(values, resetForm);
								}}
								initialValues={formFields}
								enableReinitialize
								validationSchema={validationSchema}
								render={({ handleSubmit, errors, touched }) => (
									<div className='col-lg-6'>
										<form className='php-email-form' onSubmit={handleSubmit}>
											<div className='row gy-4'>
												<div className='col-md-6'>
													<Field
														type='text'
														name='contactName'
														className={
															'form-control' +
															(errors.contactName && touched.contactName
																? ' error-box is-invalid'
																: '')
														}
														placeholder='Your Name'
													/>
													<ErrorMessage
														name='contactName'
														component='div'
														className='is-invalid invalid-feedback'
													/>
												</div>
												<div className='col-md-6 '>
													<Field
														type='contactEmail'
														className={
															'form-control' +
															(errors.contactEmail && touched.contactEmail
																? ' error-box is-invalid'
																: '')
														}
														name='contactEmail'
														placeholder='Your Email'
													/>
													<ErrorMessage
														name='contactEmail'
														component='div'
														className='is-invalid invalid-feedback'
													/>
												</div>
												<div className='col-md-12'>
													<Field
														type='text'
														className={
															'form-control' +
															(errors.contactSubject && touched.contactSubject
																? ' error-box is-invalid'
																: '')
														}
														name='contactSubject'
														placeholder='Subject'
													/>
													<ErrorMessage
														name='contactSubject'
														component='div'
														className='is-invalid invalid-feedback'
													/>
												</div>
												<div className='col-md-12'>
													<Field
														as='textarea'
														className={
															'form-control' +
															(errors.contactMessage && touched.contactMessage
																? ' error-box is-invalid'
																: '')
														}
														name='contactMessage'
														rows={6}
														placeholder='Message'
													/>
												</div>
												<div className='col-md-12 text-center'>
													<button type='submit'>Send Message</button>
												</div>
											</div>
										</form>
									</div>
								)}
							/>
							<div className='col-lg-6'>
								<div
									className='row gy-4'
									data-aos='zoom-out'
									data-aos-delay={200}>
									<img
										src={
											require('../../assets/front_assets/assets/img/contact-us.png')
												.default
										}
										className='img-fluid'
										alt=''
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* End Contact Section */}
			</main>
		</>
	);
};

export default ContactUs;
